import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import ReactMarkdown from 'react-markdown';
import { Container } from 'react-grid-system';

import Hero from '../components/rows/hero/index';
import Banner from '../images/hero-images/ecg-hero.jpg';
import TwoCol from '../components/rows/twocol/index';
import Follow from '../components/rows/cta/follow';
import Cta from '../components/rows/cta/index';
import Accordion from '../components/elements/accordion';

export default function ECG({ data }) {
  return (
    <Layout>
      <Seo
        title="ECGs"
        description="Can’t make it into PACE? Perform your own ECG at home using a HeartCheck CardiBeat Handheld ECG 
          device to record your ECG & your cardiologist will interpret it. The cost of this device is $150 + HST."
      />
      <Hero title="ECGs" banner={Banner} />
      {data.allStrapiEcGs.edges.map(({ node }) => (
        <React.Fragment key={node}>
          <TwoCol
            key={node.ecg1.id}
            title={node.ecg1.title}
            description={node.ecg1.description}
            link={node.ecg1.button_title}
            href={node.ecg1.button_link}
            newTab
            image={node.ecg1.image.localFile.publicURL}
          />
          {/* <div className="bg-gray-100 py-52 my-56">
            <Container>
              <h2 className="text-center">
                <span className="text-red-300">ECG</span> Key Features
              </h2>
              <ReactMarkdown
                className="md:w-1/2 mx-auto text-center"
                children={node.accordion_blurb}
                linkTarget="_blank"
              />
              <Accordion data={node.ecg_acc} />
            </Container>
          </div> */}
          {/* <TwoCol
            key={node.ecg2.id}
            reverse
            mailto
            pdf
            newTab
            title={node.ecg2.title}
            description={node.ecg2.description}
            link={node.ecg2.button_title}
            href={node.ecg2.button_link}
            image={node.ecg2.image.localFile.publicURL}
          /> */}
          {/* {!!node.ecg3 && node.ecg3.image ? (
            <TwoCol
              key={node.ecg3.id}
              title={node.ecg3.title}
              description={node.ecg3.description}
              link={node.ecg3.button_title}
              href={node.ecg3.button_link}
              image={node.ecg3.image.localFile.publicURL}
            />
          ) : null} */}
        </React.Fragment>
      ))}
      {/* <Cta /> */}
      <Follow />
    </Layout>
  );
}

export const query = graphql`
  query ECG {
    allStrapiEcGs {
      edges {
        node {
          id # id of the node
          ecg1 {
            id
            title
            description
            button_title
            button_link
            image {
              localFile {
                publicURL
              }
            }
          }
          accordion_blurb
          ecg_acc {
            id
            title
            description
          }
          ecg2 {
            id
            title
            description
            button_title
            button_link
            image {
              localFile {
                publicURL
              }
            }
          }
          ecg3 {
            id
            title
            description
            button_title
            button_link
            image {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;
